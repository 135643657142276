import { SupportedLocale } from '@stafftastic/api';

import fontsURL from './styles/fonts.scss?url';
import { appendStylesheetToDocument, isSupportedLocale, showRuntimeError, showRuntimeWarning } from './utils';

const currentScript = typeof document !== 'undefined' ?
  document.querySelector('script[data-space-id]') as HTMLScriptElement | null
  : null;

const containerId = currentScript?.getAttribute('data-target');
const spaceId = currentScript?.getAttribute('data-space-id');
const paramNamespace = currentScript?.getAttribute('data-param-namespace');
const locale = currentScript?.getAttribute('data-locale');

const init = async () => {
  if (!containerId) {
    return showRuntimeError('Missing "data-target" attribute');
  }

  const mountTarget = document.querySelector(`#${containerId}`);
  if (!mountTarget) {
    return showRuntimeError(`Cannot find element with id attribute set as "${containerId}"`);
  }

  if (!currentScript || !spaceId) {
    return showRuntimeError('Missing "data-space-id" attribute');
  }

  if (locale && !isSupportedLocale(locale)) {
    showRuntimeWarning(`Invalid locale "${locale}". Using defaulting to "de".`);
  }

  appendStylesheetToDocument(fontsURL);

  const shadowRoot = mountTarget.attachShadow({ mode: 'open' });
  const { mount } = await import('./app');
  await mount(shadowRoot, {
    spaceId,
    paramNamespace,
    locale: locale && isSupportedLocale(locale)
      ? locale
      : SupportedLocale.DE,
  });
};

init();
