import { SupportedLocale } from '@stafftastic/api';

export function getCurrentScriptBaseUrl(script: HTMLScriptElement) {
  const url = new URL(script.src);
  const path = url ? url.pathname.split('/') : [];
  path.pop();
  return new URL(path.join('/'), url.origin).toString();
}

export const isSupportedLocale = (locale: string): locale is SupportedLocale => {
  return Object.values(SupportedLocale).includes(locale as SupportedLocale);
};

export function showRuntimeError(message: string) {
  console.error(`[praktikumio-spaces]: Could not initialize praktikum.io space. ${message}`);
}
export function showRuntimeWarning(message: string) {
  console.warn(`[praktikumio-spaces]: ${message}`);
}

export function prependStylesheetToShadowRoot(host: ShadowRoot, url: string) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  host.prepend(link);
}

export function appendStylesheetToDocument(url: string) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  document.head.appendChild(link);
}
